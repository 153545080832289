import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useFrame } from '@react-three/fiber'
// import { scale } from "maath/dist/declarations/src/vector2";
// import * as THREE from "three";

export function DemoBot(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF(
        "/assets/female_black_test_twelve_Surge.glb"
    );
    const { actions } = useAnimations(animations, group);

    let color_map={
        FACE:"",
        HAIRS:"",
        EYES:"",
        EYEBROWS:"",
        EYEBALLS:"",
        EARS:""
    }

    return (
        <group ref={group} {...props} dispose={null}>
            <group name="Scene">
                {/*<group name="rig-female_black"  scale={7.5} position={[0,-10.1, -0.01]}>*/}
                {/*//FIRST ONE IS TO MOVE X THEAT IS HORIZONTALLY SECOND IN TOP DOWN Y AND THIRD FROWARD BACKWARD*/}
                {/*<group name="rig-female_black"  scale={15.8} position={[0,-24.1, -4.0]}>*/}
                <group name="rig-female_black"  scale={18.8} position={[0,-28.1, -4.0]}>
                    <skinnedMesh
                        name="Geo_Female_Black_Eyebrow"
                        geometry={nodes.Geo_Female_Black_Eyebrow.geometry}
                        material={materials.mat_female_black_hairs}
                        skeleton={nodes.Geo_Female_Black_Eyebrow.skeleton}
                    />
                    <group name="Geo_Female_Black_Body">
                        <skinnedMesh
                            name="head003"
                            geometry={nodes.head003.geometry}
                            material={materials.mat_female_black_body_skin}
                            skeleton={nodes.head003.skeleton}
                            material-color="#ecbcb4"
                        />
                        <skinnedMesh
                            name="head003_1"
                            geometry={nodes.head003_1.geometry}
                            material={materials.mat_female_black_body_skin}
                            skeleton={nodes.head003_1.skeleton}
                            // material-color={props.customization ? color_map.FACE : console.log("DEFAULT MATERIAL ON FACE")}
                        />
                    </group>
                    <group name="Geo_Female_Black_Eyes">
                        {/* EYE AREA WITHOUT EYE BALLS */}
                        <skinnedMesh
                            name="Sphere"
                            geometry={nodes.Sphere.geometry}
                            material={materials.mat_female_black_white_eyes}
                            skeleton={nodes.Sphere.skeleton}
                            material-color={props.customization ? color_map.EYES : console.log("DEFAULT MATERIAL ON FACE")}
                        />
                        {/*EYE BALLS  */}
                        <skinnedMesh
                            name="Sphere_1"
                            geometry={nodes.Sphere_1.geometry}
                            material={materials.mat_female_black_black_eyes}
                            skeleton={nodes.Sphere_1.skeleton}
                            // material-color={props.customization ? color_map.EYEBALLS : console.log("DEFAULT MATERIAL ON FACE")}
                        />
                    </group>
                    <skinnedMesh
                        // HAIRS
                        name="Geo_Female_Black_Hair010"
                        geometry={nodes.Geo_Female_Black_Hair010.geometry}
                        material={materials.mat_female_black_hairs}
                        skeleton={nodes.Geo_Female_Black_Hair010.skeleton}
                        // material-color={props.customization ? color_map.HAIRS : console.log("DEFAULT MATERIAL ON FACE")}
                        // material-color={props.customization ? "green" : console.log("DEFAULT MATERIAL ON FACE")}
                        material-color="green"
                    />
                    <group name="Geo_Male_white_Cartoon_Lower_Teeth">
                        <skinnedMesh
                            name="Plane008"
                            geometry={nodes.Plane008.geometry}
                            material={materials.mat_male_white_gums}
                            skeleton={nodes.Plane008.skeleton}
                        />
                        <skinnedMesh
                            name="Plane008_1"
                            geometry={nodes.Plane008_1.geometry}
                            material={materials.mat_male_white_teeth}
                            skeleton={nodes.Plane008_1.skeleton}
                        />
                    </group>
                    <group name="Geo_Male_white_Cartoon_Upper_Teeth">
                        <skinnedMesh
                            name="Plane009"
                            geometry={nodes.Plane009.geometry}
                            material={materials.mat_male_white_gums}
                            skeleton={nodes.Plane009.skeleton}
                        />
                        <skinnedMesh
                            name="Plane009_1"
                            geometry={nodes.Plane009_1.geometry}
                            material={materials.mat_male_white_teeth}
                            skeleton={nodes.Plane009_1.skeleton}
                        />
                    </group>
                    {/*<skinnedMesh*/}
                    {/*    name="Geo_Male_White_Ears"*/}
                    {/*    geometry={nodes.Geo_Male_White_Ears.geometry}*/}
                    {/*    skeleton={nodes.Geo_Male_White_Ears.skeleton}*/}
                    {/*    material-color="#ecbcb4"*/}
                    {/*/>*/}
                    <group name="Geo_Male_White_Lower_Teeth">
                        <skinnedMesh
                            name="Mesh004"
                            geometry={nodes.Mesh004.geometry}
                            material={materials.mat_female_black_gums}
                            skeleton={nodes.Mesh004.skeleton}
                            morphTargetDictionary={nodes.Mesh004.morphTargetDictionary}
                            morphTargetInfluences={nodes.Mesh004.morphTargetInfluences}
                        />
                        <skinnedMesh
                            name="Mesh004_1"
                            geometry={nodes.Mesh004_1.geometry}
                            material={materials.mat_female_black_teeth}
                            skeleton={nodes.Mesh004_1.skeleton}
                            morphTargetDictionary={nodes.Mesh004_1.morphTargetDictionary}
                            morphTargetInfluences={nodes.Mesh004_1.morphTargetInfluences}
                        />
                    </group>
                    <skinnedMesh
                        name="Geo_Male_White_Tongue"
                        geometry={nodes.Geo_Male_White_Tongue.geometry}
                        material={materials.mat_female_black_gums}
                        skeleton={nodes.Geo_Male_White_Tongue.skeleton}
                    />
                    <group name="Geo_Male_White_Upper_Teeth">
                        <skinnedMesh
                            name="Mesh006"
                            geometry={nodes.Mesh006.geometry}
                            material={materials.mat_female_black_gums}
                            skeleton={nodes.Mesh006.skeleton}
                            morphTargetDictionary={nodes.Mesh006.morphTargetDictionary}
                            morphTargetInfluences={nodes.Mesh006.morphTargetInfluences}
                        />
                        <skinnedMesh
                            name="Mesh006_1"
                            geometry={nodes.Mesh006_1.geometry}
                            material={materials.mat_female_black_teeth}
                            skeleton={nodes.Mesh006_1.skeleton}
                            morphTargetDictionary={nodes.Mesh006_1.morphTargetDictionary}
                            morphTargetInfluences={nodes.Mesh006_1.morphTargetInfluences}
                        />
                    </group>
                    <primitive object={nodes.root} />
                    <primitive object={nodes["ORG-spine006"]} />
                    <primitive object={nodes["ORG-handL"]} />
                    <primitive object={nodes["ORG-handR"]} />
                </group>
                <group
                    name="cs-generator-main"
                    position={[2.2, 1.081, 0]}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={0.237}
                >
                    <group
                        name="WGTS_rig-female_black"
                        position={[-9.301, 0, 4.571]}
                        rotation={[-Math.PI / 2, 0, 0]}
                        scale={4.228}
                    >
                        <group
                            name="WGT-rig-female_black_browBL"
                            position={[0.07, 1.53, 0.103]}
                            rotation={[Math.PI / 2, 0, 0.196]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browBL001"
                            position={[0.057, 1.536, 0.116]}
                            rotation={[Math.PI / 2, 0, 0.035]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browBL002"
                            position={[0.044, 1.537, 0.123]}
                            rotation={[Math.PI / 2, 0, -0.015]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browBL003"
                            position={[0.027, 1.534, 0.127]}
                            rotation={[Math.PI / 2, 0, 0.088]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browBL004"
                            position={[0.021, 1.526, 0.126]}
                            rotation={[Math.PI / 2, 0, 0.088]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browBR"
                            position={[-0.07, 1.53, 0.103]}
                            rotation={[Math.PI / 2, 0, -0.196]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browBR001"
                            position={[-0.057, 1.536, 0.116]}
                            rotation={[Math.PI / 2, 0, -0.035]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browBR002"
                            position={[-0.044, 1.537, 0.123]}
                            rotation={[Math.PI / 2, 0, 0.015]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browBR003"
                            position={[-0.027, 1.534, 0.127]}
                            rotation={[Math.PI / 2, 0, -0.088]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browBR004"
                            position={[-0.021, 1.526, 0.126]}
                            rotation={[Math.PI / 2, 0, -0.088]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browTL"
                            position={[0.081, 1.503, 0.086]}
                            rotation={[Math.PI / 2, 0, 0.022]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browTL001"
                            position={[0.074, 1.537, 0.102]}
                            rotation={[Math.PI / 2, 0, -0.488]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browTL002"
                            position={[0.046, 1.552, 0.129]}
                            rotation={[Math.PI / 2, 0, 0.012]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browTL003"
                            position={[0.024, 1.544, 0.136]}
                            rotation={[Math.PI / 2, 0, -0.216]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browTR"
                            position={[-0.081, 1.503, 0.086]}
                            rotation={[Math.PI / 2, 0, -0.022]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browTR001"
                            position={[-0.074, 1.537, 0.102]}
                            rotation={[Math.PI / 2, 0, 0.488]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browTR002"
                            position={[-0.046, 1.552, 0.129]}
                            rotation={[Math.PI / 2, 0, -0.012]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_browTR003"
                            position={[-0.024, 1.544, 0.136]}
                            rotation={[Math.PI / 2, 0, 0.216]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_cheekBL001"
                            position={[0.07, 1.445, 0.087]}
                            rotation={[Math.PI / 2, 0, -0.007]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_cheekBR001"
                            position={[-0.07, 1.445, 0.087]}
                            rotation={[Math.PI / 2, 0, 0.007]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_cheekTL001"
                            position={[0.043, 1.478, 0.119]}
                            rotation={[Math.PI / 2, 0, -0.062]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_cheekTR001"
                            position={[-0.043, 1.478, 0.119]}
                            rotation={[Math.PI / 2, 0, 0.062]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_chin"
                            position={[0, 1.399, 0.112]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_chin001"
                            position={[0, 1.407, 0.124]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_chin002"
                            position={[0, 1.424, 0.133]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_chinL"
                            position={[0.029, 1.409, 0.11]}
                            rotation={[Math.PI / 2, 0, -0.082]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_chinR"
                            position={[-0.029, 1.409, 0.11]}
                            rotation={[Math.PI / 2, 0, 0.082]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_earL"
                            position={[0.082, 1.47, 0.011]}
                            rotation={[1.509, -0.187, 0.196]}
                            scale={0.038}
                        />
                        <group
                            name="WGT-rig-female_black_earL002"
                            position={[0.103, 1.517, -0.004]}
                            rotation={[Math.PI / 2, 0, 0.211]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_earL003"
                            position={[0.107, 1.5, -0.013]}
                            rotation={[Math.PI / 2, 0, -0.051]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_earL004"
                            position={[0.094, 1.474, 0.002]}
                            rotation={[Math.PI / 2, 0, 2.221]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_earR"
                            position={[-0.082, 1.47, 0.011]}
                            rotation={[1.509, 0.187, -0.196]}
                            scale={0.038}
                        />
                        <group
                            name="WGT-rig-female_black_earR002"
                            position={[-0.103, 1.517, -0.004]}
                            rotation={[Math.PI / 2, 0, -0.211]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_earR003"
                            position={[-0.107, 1.5, -0.013]}
                            rotation={[Math.PI / 2, 0, 0.051]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_earR004"
                            position={[-0.094, 1.474, 0.002]}
                            rotation={[Math.PI / 2, 0, -2.221]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_eyeL"
                            position={[0.039, 1.513, 0.331]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.025}
                        />
                        <group
                            name="WGT-rig-female_black_eyeR"
                            position={[-0.039, 1.513, 0.331]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.025}
                        />
                        <group
                            name="WGT-rig-female_black_eyes"
                            position={[0, 1.513, 0.331]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.025}
                        />
                        <group
                            name="WGT-rig-female_black_f_index01L"
                            position={[0.535, 0.894, 0.034]}
                            rotation={[-1.763, -0.622, 1.178]}
                            scale={0.036}
                        />
                        <group
                            name="WGT-rig-female_black_f_index01L001"
                            position={[0.575, 0.824, 0.044]}
                            rotation={[1.481, 0.386, 1.296]}
                            scale={0.011}
                        />
                        <group
                            name="WGT-rig-female_black_f_index01R"
                            position={[-0.535, 0.894, 0.034]}
                            rotation={[-1.763, 0.622, -1.178]}
                            scale={0.036}
                        />
                        <group
                            name="WGT-rig-female_black_f_index01R001"
                            position={[-0.575, 0.824, 0.044]}
                            rotation={[1.481, -0.386, -1.296]}
                            scale={0.011}
                        />
                        <group
                            name="WGT-rig-female_black_f_index01_masterL"
                            position={[0.535, 0.894, 0.034]}
                            rotation={[-1.726, -0.544, 1.292]}
                            scale={0.136}
                        />
                        <group
                            name="WGT-rig-female_black_f_index01_masterR"
                            position={[-0.535, 0.894, 0.034]}
                            rotation={[-1.726, 0.544, -1.292]}
                            scale={0.136}
                        />
                        <group
                            name="WGT-rig-female_black_f_index02L"
                            position={[0.556, 0.865, 0.039]}
                            rotation={[-1.695, -0.477, 1.213]}
                            scale={0.023}
                        />
                        <group
                            name="WGT-rig-female_black_f_index02R"
                            position={[-0.556, 0.865, 0.039]}
                            rotation={[-1.695, 0.477, -1.213]}
                            scale={0.023}
                        />
                        <group
                            name="WGT-rig-female_black_f_index03L"
                            position={[0.566, 0.845, 0.042]}
                            rotation={[-1.661, -0.386, 1.228]}
                            scale={0.022}
                        />
                        <group
                            name="WGT-rig-female_black_f_index03R"
                            position={[-0.566, 0.845, 0.042]}
                            rotation={[-1.661, 0.386, -1.228]}
                            scale={0.022}
                        />
                        <group
                            name="WGT-rig-female_black_f_middle01L"
                            position={[0.536, 0.894, 0.012]}
                            rotation={[-1.571, -0.729, 1.571]}
                            scale={0.046}
                        />
                        <group
                            name="WGT-rig-female_black_f_middle01L001"
                            position={[0.587, 0.82, 0.012]}
                            rotation={[Math.PI / 2, 0.358, 1.571]}
                            scale={0.01}
                        />
                        <group
                            name="WGT-rig-female_black_f_middle01R"
                            position={[-0.536, 0.894, 0.012]}
                            rotation={[-1.571, 0.729, -1.571]}
                            scale={0.046}
                        />
                        <group
                            name="WGT-rig-female_black_f_middle01R001"
                            position={[-0.587, 0.82, 0.012]}
                            rotation={[Math.PI / 2, -0.358, -1.571]}
                            scale={0.01}
                        />
                        <group
                            name="WGT-rig-female_black_f_middle01_masterL"
                            position={[0.536, 0.894, 0.012]}
                            rotation={[-Math.PI / 2, -0.635, 1.666]}
                            scale={0.158}
                        />
                        <group
                            name="WGT-rig-female_black_f_middle01_masterR"
                            position={[-0.536, 0.894, 0.012]}
                            rotation={[-Math.PI / 2, 0.635, -1.666]}
                            scale={0.158}
                        />
                        <group
                            name="WGT-rig-female_black_f_middle02L"
                            position={[0.567, 0.86, 0.012]}
                            rotation={[-Math.PI / 2, -0.55, 1.571]}
                            scale={0.025}
                        />
                        <group
                            name="WGT-rig-female_black_f_middle02R"
                            position={[-0.567, 0.86, 0.012]}
                            rotation={[-Math.PI / 2, 0.55, -1.571]}
                            scale={0.025}
                        />
                        <group
                            name="WGT-rig-female_black_f_middle03L"
                            position={[0.58, 0.839, 0.012]}
                            rotation={[-Math.PI / 2, -0.358, 1.571]}
                            scale={0.021}
                        />
                        <group
                            name="WGT-rig-female_black_f_middle03R"
                            position={[-0.58, 0.839, 0.012]}
                            rotation={[-Math.PI / 2, 0.358, -1.571]}
                            scale={0.021}
                        />
                        <group
                            name="WGT-rig-female_black_f_pinky01L"
                            position={[0.53, 0.901, -0.025]}
                            rotation={[-1.259, -0.589, 2.18]}
                            scale={0.026}
                        />
                        <group
                            name="WGT-rig-female_black_f_pinky01L001"
                            position={[0.564, 0.847, -0.04]}
                            rotation={[1.823, 0.494, 1.934]}
                            scale={0.01}
                        />
                        <group
                            name="WGT-rig-female_black_f_pinky01R"
                            position={[-0.53, 0.901, -0.025]}
                            rotation={[-1.259, 0.589, -2.18]}
                            scale={0.026}
                        />
                        <group
                            name="WGT-rig-female_black_f_pinky01R001"
                            position={[-0.564, 0.847, -0.04]}
                            rotation={[1.823, -0.494, -1.934]}
                            scale={0.01}
                        />
                        <group
                            name="WGT-rig-female_black_f_pinky01_masterL"
                            position={[0.53, 0.901, -0.025]}
                            rotation={[-1.288, -0.555, 2.222]}
                            scale={0.107}
                        />
                        <group
                            name="WGT-rig-female_black_f_pinky01_masterR"
                            position={[-0.53, 0.901, -0.025]}
                            rotation={[-1.288, 0.555, -2.222]}
                            scale={0.107}
                        />
                        <group
                            name="WGT-rig-female_black_f_pinky02L"
                            position={[0.545, 0.88, -0.031]}
                            rotation={[-1.323, -0.538, 2.175]}
                            scale={0.02}
                        />
                        <group
                            name="WGT-rig-female_black_f_pinky02R"
                            position={[-0.545, 0.88, -0.031]}
                            rotation={[-1.323, 0.538, -2.175]}
                            scale={0.02}
                        />
                        <group
                            name="WGT-rig-female_black_f_pinky03L"
                            position={[0.555, 0.864, -0.035]}
                            rotation={[-1.319, -0.494, 2.177]}
                            scale={0.02}
                        />
                        <group
                            name="WGT-rig-female_black_f_pinky03R"
                            position={[-0.555, 0.864, -0.035]}
                            rotation={[-1.319, 0.494, -2.177]}
                            scale={0.02}
                        />
                        <group
                            name="WGT-rig-female_black_f_ring01L"
                            position={[0.537, 0.895, -0.007]}
                            rotation={[-1.397, -0.704, 1.81]}
                            scale={0.037}
                        />
                        <group
                            name="WGT-rig-female_black_f_ring01L001"
                            position={[0.578, 0.829, -0.016]}
                            rotation={[1.646, 0.37, 1.706]}
                            scale={0.009}
                        />
                        <group
                            name="WGT-rig-female_black_f_ring01R"
                            position={[-0.537, 0.895, -0.007]}
                            rotation={[-1.397, 0.704, -1.81]}
                            scale={0.037}
                        />
                        <group
                            name="WGT-rig-female_black_f_ring01R001"
                            position={[-0.578, 0.829, -0.016]}
                            rotation={[1.646, -0.37, -1.706]}
                            scale={0.009}
                        />
                        <group
                            name="WGT-rig-female_black_f_ring01_masterL"
                            position={[0.537, 0.895, -0.007]}
                            rotation={[-1.427, -0.601, 1.884]}
                            scale={0.135}
                        />
                        <group
                            name="WGT-rig-female_black_f_ring01_masterR"
                            position={[-0.537, 0.895, -0.007]}
                            rotation={[-1.427, 0.601, -1.884]}
                            scale={0.135}
                        />
                        <group
                            name="WGT-rig-female_black_f_ring02L"
                            position={[0.561, 0.867, -0.012]}
                            rotation={[-1.446, -0.485, 1.781]}
                            scale={0.024}
                        />
                        <group
                            name="WGT-rig-female_black_f_ring02R"
                            position={[-0.561, 0.867, -0.012]}
                            rotation={[-1.446, 0.485, -1.781]}
                            scale={0.024}
                        />
                        <group
                            name="WGT-rig-female_black_f_ring03L"
                            position={[0.572, 0.846, -0.015]}
                            rotation={[-1.495, -0.37, 1.761]}
                            scale={0.018}
                        />
                        <group
                            name="WGT-rig-female_black_f_ring03R"
                            position={[-0.572, 0.846, -0.015]}
                            rotation={[-1.495, 0.37, -1.761]}
                            scale={0.018}
                        />
                        <group
                            name="WGT-rig-female_black_hand_ikL"
                            position={[0.471, 0.952, 0.003]}
                            rotation={[-1.642, -0.823, 3.003]}
                            scale={0.074}
                        />
                        <group
                            name="WGT-rig-female_black_hand_ikR"
                            position={[-0.471, 0.952, 0.003]}
                            rotation={[-1.642, 0.823, -3.003]}
                            scale={0.074}
                        />
                        <group
                            name="WGT-rig-female_black_head"
                            position={[0, 1.465, 0.031]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.198}
                        />
                        <group
                            name="WGT-rig-female_black_jaw"
                            position={[0, 1.403, 0.082]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_jawL"
                            position={[0.082, 1.48, 0.02]}
                            rotation={[Math.PI / 2, 0, -0.136]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_jawL001"
                            position={[0.067, 1.439, 0.038]}
                            rotation={[Math.PI / 2, 0, -0.579]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_jawR"
                            position={[-0.082, 1.48, 0.02]}
                            rotation={[Math.PI / 2, 0, 0.136]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_jawR001"
                            position={[-0.067, 1.439, 0.038]}
                            rotation={[Math.PI / 2, 0, 0.579]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_jaw_master"
                            position={[0, 1.48, 0.02]}
                            rotation={[-2.416, 0, 0]}
                            scale={0.123}
                        />
                        <group
                            name="WGT-rig-female_black_lidBL"
                            position={[0.021, 1.507, 0.118]}
                            rotation={[Math.PI / 2, 0, -0.716]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidBL001"
                            position={[0.029, 1.499, 0.118]}
                            rotation={[Math.PI / 2, 0, -0.268]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidBL002"
                            position={[0.044, 1.495, 0.116]}
                            rotation={[Math.PI / 2, 0, 0.015]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidBL003"
                            position={[0.061, 1.5, 0.105]}
                            rotation={[Math.PI / 2, 0, 0.155]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidBR"
                            position={[-0.021, 1.507, 0.118]}
                            rotation={[Math.PI / 2, 0, 0.716]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidBR001"
                            position={[-0.029, 1.499, 0.118]}
                            rotation={[Math.PI / 2, 0, 0.268]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidBR002"
                            position={[-0.044, 1.495, 0.116]}
                            rotation={[Math.PI / 2, 0, -0.015]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidBR003"
                            position={[-0.061, 1.5, 0.105]}
                            rotation={[Math.PI / 2, 0, -0.155]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidTL"
                            position={[0.068, 1.512, 0.098]}
                            rotation={[Math.PI / 2, 0, -0.779]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidTL001"
                            position={[0.065, 1.522, 0.103]}
                            rotation={[Math.PI / 2, 0, -0.268]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidTL002"
                            position={[0.044, 1.528, 0.122]}
                            rotation={[Math.PI / 2, 0, 0.253]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidTL003"
                            position={[0.027, 1.52, 0.124]}
                            rotation={[Math.PI / 2, 0, 0.306]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidTR"
                            position={[-0.068, 1.512, 0.098]}
                            rotation={[Math.PI / 2, 0, 0.779]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidTR001"
                            position={[-0.065, 1.522, 0.103]}
                            rotation={[Math.PI / 2, 0, 0.268]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidTR002"
                            position={[-0.044, 1.528, 0.122]}
                            rotation={[Math.PI / 2, 0, -0.253]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lidTR003"
                            position={[-0.027, 1.52, 0.124]}
                            rotation={[Math.PI / 2, 0, -0.306]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lipB"
                            position={[0, 1.438, 0.139]}
                            rotation={[Math.PI / 2, 0, -0.256]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lipBL001"
                            position={[0.018, 1.439, 0.129]}
                            rotation={[Math.PI / 2, 0, 0.004]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lipBR001"
                            position={[-0.018, 1.439, 0.129]}
                            rotation={[Math.PI / 2, 0, -0.004]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lipT"
                            position={[0, 1.439, 0.139]}
                            rotation={[Math.PI / 2, 0, -0.13]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lipTL001"
                            position={[0.018, 1.44, 0.13]}
                            rotation={[Math.PI / 2, 0, 0.236]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lipTR001"
                            position={[-0.018, 1.44, 0.13]}
                            rotation={[Math.PI / 2, 0, -0.236]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lipsL"
                            position={[0.036, 1.443, 0.116]}
                            rotation={[Math.PI / 2, 0, 0.236]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_lipsR"
                            position={[-0.036, 1.443, 0.116]}
                            rotation={[Math.PI / 2, 0, -0.236]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_master_eyeL"
                            position={[0.039, 1.513, 0.097]}
                            rotation={[Math.PI, 0, Math.PI]}
                            scale={0.024}
                        />
                        <group
                            name="WGT-rig-female_black_master_eyeR"
                            position={[-0.039, 1.513, 0.097]}
                            rotation={[Math.PI, 0, Math.PI]}
                            scale={0.024}
                        />
                        <group
                            name="WGT-rig-female_black_nose"
                            position={[0, 1.533, 0.137]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_nose001"
                            position={[0, 1.509, 0.133]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_nose002"
                            position={[0, 1.47, 0.156]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_nose003"
                            position={[0, 1.463, 0.149]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_nose004"
                            position={[0, 1.458, 0.141]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_nose005"
                            position={[0, 1.452, 0.144]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_noseL"
                            position={[0.016, 1.497, 0.126]}
                            rotation={[Math.PI / 2, 0, 0.103]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_noseL001"
                            position={[0.02, 1.468, 0.134]}
                            rotation={[Math.PI / 2, 0, -0.158]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_noseR"
                            position={[-0.016, 1.497, 0.126]}
                            rotation={[Math.PI / 2, 0, -0.103]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_noseR001"
                            position={[-0.02, 1.468, 0.134]}
                            rotation={[Math.PI / 2, 0, 0.158]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_nose_master"
                            position={[0, 1.458, 0.141]}
                            rotation={[Math.PI, 0, Math.PI]}
                            scale={0.023}
                        />
                        <group
                            name="WGT-rig-female_black_palmL"
                            position={[0.49, 0.933, -0.013]}
                            rotation={[-1.223, -0.876, 1.984]}
                            scale={0.053}
                        />
                        <group
                            name="WGT-rig-female_black_palmR"
                            position={[-0.49, 0.933, -0.013]}
                            rotation={[-1.223, 0.876, -1.984]}
                            scale={0.053}
                        />
                        <group
                            name="WGT-rig-female_black_root"
                            position={[0, 1.2, 0]}
                            scale={0.718}
                        />
                        <group
                            name="WGT-rig-female_black_teethB"
                            position={[0, 1.429, 0.128]}
                            rotation={[0.029, 0, 0]}
                            scale={0.044}
                        />
                        <group
                            name="WGT-rig-female_black_teethT"
                            position={[0, 1.445, 0.13]}
                            rotation={[-0.003, 0, 0]}
                            scale={0.045}
                        />
                        <group
                            name="WGT-rig-female_black_thumb01L"
                            position={[0.483, 0.932, 0.025]}
                            rotation={[-2.337, -0.282, -0.267]}
                            scale={0.032}
                        />
                        <group
                            name="WGT-rig-female_black_thumb01L001"
                            position={[0.511, 0.864, 0.073]}
                            rotation={[1.138, 0.351, 0.154]}
                            scale={0.011}
                        />
                        <group
                            name="WGT-rig-female_black_thumb01R"
                            position={[-0.483, 0.932, 0.025]}
                            rotation={[-2.337, 0.282, 0.267]}
                            scale={0.032}
                        />
                        <group
                            name="WGT-rig-female_black_thumb01R001"
                            position={[-0.511, 0.864, 0.073]}
                            rotation={[1.138, -0.351, -0.154]}
                            scale={0.011}
                        />
                        <group
                            name="WGT-rig-female_black_thumb01_masterL"
                            position={[0.483, 0.932, 0.025]}
                            rotation={[-2.22, -0.311, -0.234]}
                            scale={0.141}
                        />
                        <group
                            name="WGT-rig-female_black_thumb01_masterR"
                            position={[-0.483, 0.932, 0.025]}
                            rotation={[-2.22, 0.311, 0.234]}
                            scale={0.141}
                        />
                        <group
                            name="WGT-rig-female_black_thumb02L"
                            position={[0.492, 0.91, 0.047]}
                            rotation={[-2.148, -0.327, -0.21]}
                            scale={0.034}
                        />
                        <group
                            name="WGT-rig-female_black_thumb02R"
                            position={[-0.492, 0.91, 0.047]}
                            rotation={[-2.148, 0.327, 0.21]}
                            scale={0.034}
                        />
                        <group
                            name="WGT-rig-female_black_thumb03L"
                            position={[0.503, 0.882, 0.065]}
                            rotation={[-2.004, -0.351, -0.162]}
                            scale={0.021}
                        />
                        <group
                            name="WGT-rig-female_black_thumb03R"
                            position={[-0.503, 0.882, 0.065]}
                            rotation={[-2.004, 0.351, 0.162]}
                            scale={0.021}
                        />
                        <group
                            name="WGT-rig-female_black_tongue"
                            position={[0, 1.43, 0.12]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_tongue001"
                            position={[0, 1.437, 0.098]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_tongue002"
                            position={[0, 1.433, 0.059]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_tongue003"
                            position={[0, 1.424, 0.027]}
                            rotation={[Math.PI / 2, 0, 0]}
                            scale={0.013}
                        />
                        <group
                            name="WGT-rig-female_black_tongue_master"
                            position={[0, 1.437, 0.098]}
                            rotation={[-2.858, 0, 0]}
                            scale={0.023}
                        />
                    </group>
                </group>
                <group
                    name="cs-generator-main001"
                    position={[2.2, 1.081, 0]}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={0.237}
                />
            </group>
        </group>
    );
}

useGLTF.preload("/assets/female_black_test_twelve_Surge.glb");