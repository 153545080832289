import React, { useEffect, useRef, useState } from 'react'
import {
    Canvas,
    useFrame,
    extend,
    useThree
} from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {DemoBot} from "./DemoBot";
import styles from "./DemoBotStyles/DemoBotUi.module.css"
import {
    Environment,
    MeshPortalMaterial,
    RoundedBox,
    Text,
    useCursor,
    useTexture,
} from "@react-three/drei";
import { easing } from "maath";
import * as THREE from "three";
extend({ OrbitControls });

const CameraControls = () => {
    // Get a reference to the Three.js Camera, and the canvas html element.
    // We need these to setup the OrbitControls class.
    // https://threejs.org/docs/#examples/en/controls/OrbitControls

    const {
        camera,
        gl: { domElement }
    } = useThree();

    // Ref to the controls, so that we can update them on every frame using useFrame
    const controls = useRef();
    useFrame(state => controls.current.update());
    return (
        <orbitControls
            ref={controls}
            args={[camera, domElement]}
            enableZoom={false}
            maxAzimuthAngle={Math.PI / 4}
            maxPolarAngle={Math.PI}
            minAzimuthAngle={-Math.PI / 4}
            minPolarAngle={0}
        />
    );
};


function DemoBotUi(props) {
    return (
        <div className={styles.bot_container}>
            <Canvas>
                {/*<CameraControls/>*/}
                {/*<ambientLight intensity={0.1} />*/}
                <ambientLight intensity={0.1} />
                <directionalLight color="white" position={[0, 0, 5]} />
                {/*<directionalLight color="white" position={[0, 0, 5]} />*/}
                <DemoBot/>
                {/*<Environment preset="sunset" background />*/}

            </Canvas>
        </div>
    );
}

export default DemoBotUi;