import React, {useEffect, useState} from 'react';
import styles from "../../../Componentsstyles/Hero.module.css"
import Header from "./HeaderSection/Header";
import DemoBotUi from "./BotSection/DemoBotUi";
import HelloAnimation from "./WelcomeSection/WELCOME_ANIMATION/HelloAnimation";
import Namaskar from "./WelcomeSection/WELCOME_ANIMATION/Namaskar";
import Hola from "./WelcomeSection/WELCOME_ANIMATION/Hola";
import Bonjour from "./WelcomeSection/WELCOME_ANIMATION/Bonjour";
import Ola from "./WelcomeSection/WELCOME_ANIMATION/Ola";
import Hai from "./WelcomeSection/WELCOME_ANIMATION/Hai";
import Salute from "./WelcomeSection/WELCOME_ANIMATION/Salute";
import Tbot from "./TbotLogoSection/TbotLogo";
import Welcome from "./WelcomeSection/Welcome";
import AmazingExperiences from "./AmazingExperiences/AmazingExperiences";
import JoinBeta from "./HeaderSection/JoinBeta";


function WritingComponent({ number}){
    if (number===1) {
        return <HelloAnimation/>
    }
    else if (number===2) {
return <Namaskar/>
    }
    else if (number===3) {
        return  <Hola/>
    }
    else if (number===4) {
        return <Bonjour/>
    }
    else if (number===5) {
        return <HelloAnimation/>
    }
    else if (number===6) {
        return <Ola/>
    }
    else if (number===7) {
        return <Hai/>
    }
    else if (number===8) {
        return  <Salute/>
    }
}



// function Hero(props) {
//     const [randomNumber, setRandomNumber] = useState(1);
//     useEffect(() => {
//         const intervalId = setInterval(() => {
//             const randomNum = Math.floor(Math.random() * 8) + 1; // Generates a random number between 1 and 8
//             // let randomNum=randomNumber+1;
//             setRandomNumber(randomNum);
//         }, 6000); // 3000 milliseconds = 3 seconds
//
//         // Clear the interval when the component is unmounted
//         return () => clearInterval(intervalId);
//     }, []); // Empty dependency array ensures that effect runs once after initial render
//
//     return (
//         <div className={styles.Hero}>
//             <Header/>
//             <h1 className={styles.tagline}>We Promise You Would ❤️ It</h1>
//             <WritingComponent number={randomNumber}/>
//             <JoinBeta/>
//             <DemoBotUi/>
//         </div>
//     );
// }


function TbotHero(props) {
    const [randomNumber, setRandomNumber] = useState(1);
    useEffect(() => {
        const intervalId = setInterval(() => {
            const randomNum = Math.floor(Math.random() * 8) + 1; // Generates a random number between 1 and 8
            // let randomNum=randomNumber+1;
            setRandomNumber(randomNum);
        }, 6000); // 3000 milliseconds = 3 seconds

        // Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures that effect runs once after initial render

    return (
        <div className={styles.Hero}>
            <p className={styles.explore}>Explore Tbot</p>
            {/*<Header/>*/}
            {/*<Tbot/>*/}
            <Welcome/>
            <DemoBotUi/>
            <AmazingExperiences/>
        </div>
    );
}

export default TbotHero;