import React from 'react';
import {AppBar, Button, Stack, Toolbar, Typography} from "@mui/material";
import styles from "./HeaderStyles/Header.module.css";
import Contact from "./Contact";
import Welcome from "./Welcome";
import JoinBeta from "./JoinBeta";



function Header(props) {
    return (
        <>
            {/*<div className={styles.header}>*/}
            {/*</div>*/}
            <div>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Contact/>
                    <Welcome/>
                    <JoinBeta/>
                </Stack>
            </div>

        </>

    );
}

export default Header;