import React, {useEffect, useState} from 'react';
import styles from "./Welcome_Section_Styles/Welcome.module.css"
import {Stack} from "@mui/material";
import AnimatedWriter from "./AnimatedWriter";

function Welcome() {
    const [randomNumber, setRandomNumber] = useState(1);
    useEffect(() => {
        const intervalId = setInterval(() => {
            const randomNum = Math.floor(Math.random() * 8) + 1; // Generates a random number between 1 and 8
            // let randomNum=randomNumber+1;
            setRandomNumber(randomNum);
        }, 6000); // 3000 milliseconds = 3 seconds

        // Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures that effect runs once after initial render

    return (
        <div className={styles.Welcome}>
            <Stack
                direction="row"
                // justifyContent="center"
                justifyContent="space-around"
                alignItems="center"
                spacing={10}
            >

<div className={styles.SayContainer}>
    <p className={styles.Say}>Say</p>
</div>
                <div className={styles.AnimatedWriterContainer}>
                    <AnimatedWriter number={randomNumber}/>
                </div>
                <div className={styles.ToContainer}>
                    <p className={styles.To}>To</p>
                </div>
            </Stack>

        </div>
    );
}

export default Welcome;