import React from 'react';
// import styles from "../../Componentsstyles/PoweredByTv.module.css"
import PoweredBy from "./Components/PoweredBy";
import ThreedVERSE from "./Components/ThreedVERSE";
import WeareHiring from "./Components/WeareHiring";
import Join from "./Components/Join";
import Footer from "../../FooterSection/Footer";
function PoweredByTv(props) {
    return (
        <div>
            <PoweredBy/>
            <ThreedVERSE/>
            <WeareHiring/>
            <Join/>
            <Footer/>
        </div>
    );
}

export default PoweredByTv;