// import styles from "./HeaderStyles/JoinBeta.module.css"
import styles from "./HeaderStyles/JoinBeta.module.css"
import React from "react";

function JoinBeta(){
    return (
        <div className={styles.buttoncenter}>
            {/*<a href="https://forms.gle/75EGyWGebadXqq5L9" className={styles.joinbutton} target="_blank" rel="noopener noreferrer">Join Beta</a>*/}
            <a href="https://www.tbots.space/"  target="_blank" rel="noopener noreferrer"><p className={styles.title}>Know More</p></a>
        </div>
    )
}


export default JoinBeta;