import React from 'react';
import styles from "./AmazingExperiencesStyles/AmazingExperiences.module.css"
import JoinBeta from "../HeaderSection/JoinBeta";
import {Stack} from "@mui/material";

function AmazingExperiences(props) {
    return (
        <>
            <div className={styles.AmazingContainer}>
                <p className={styles.Amazing}>Amazing Experiences</p>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                <JoinBeta/>
                </Stack>
            </div>
            {/*<div className={styles.border}>*/}
            {/*</div>*/}
        </>
    );
}

export default AmazingExperiences;