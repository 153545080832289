import React from 'react';
import styles from "./HeaderStyles/Welcome.module.css"
function Welcome(props) {
    return (
        <div>
            <p className={styles.Welcome}>WELCOME TO THE WORLD OF</p>
        </div>
    );
}

export default Welcome;