import React from 'react';
import {Stack} from "@mui/material";
import styles from "./VrStyling/vr.module.css";

function Vr(props) {
    return (
        <div>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <p className={styles.spatial}>A SPATIAL COMPUTER</p>
                <p className={styles.reimagine}>REIMAGINING</p>
                <p className={styles.space}>THE SPACE</p>
                <img className={styles.image} src="/assets/vrheadset.png"></img>
                <p className={styles.launch}>Launching Late 2024</p>
                <p className={styles.when}>When we Launch</p>
                <p className={styles.you}>You Will See Why <span className={styles.blueline}>2024</span> Wont Be Like <span className={styles.redline}>2024</span></p>

            </Stack>
        </div>
    );
}

export default Vr;