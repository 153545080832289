import React from 'react';
import styles from "./gKnowStyles/gKnow.module.css";
import {Stack} from "@mui/material";

function GKnow(props) {
    return (
        <div>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
<p className={styles.experience}>EXPERIENCE</p>
            <p className={styles.smart}>SMART ENGINE</p>
            <img className={styles.logo} src="/assets/gknow_logo.png"></img>
            <p className={styles.smarter}>Smarter Than You Can Think</p>
            <p className={styles.helpful}>Helpful Than You Can Imagine</p>
            </Stack>
        </div>
    );
}

export default GKnow;