import React from 'react';
import styles from "../../../../../Componentsstyles/WELCOME_ANIMATION/Hola.module.css";

function Hola(props) {
    return (
        <div className={styles.container}>
            <svg className={styles.text}  width="665" height="347" viewBox="0 0 665 347" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="665" height="347" fill="#030000"/>
                <path d="M37.2331 274.323C18.9348 274.323 45.9704 272.161 53.9263 265.42C103.287 223.601 123.894 162.548 138.691 101.085C141.108 91.0417 160.769 36.0479 134.796 41.3606C104.691 47.5183 101.935 88.4251 100.853 112.77C98.8353 158.163 99.446 203.698 97.5141 249.098C96.8359 265.036 97.6909 281.009 97.3286 296.952C96.9808 312.254 93.9721 292.824 94.7319 286.936C100.688 240.777 123.762 220.153 168.553 206.252C188.85 199.953 194.593 217.844 194.149 234.26C193.687 251.355 178.224 274.499 187.472 290.275C198.524 309.128 228.001 309.053 243.858 295.839C265.806 277.549 256.335 236.099 277.615 214.228C283.14 208.55 298.516 190.421 293.566 196.607C285.674 206.473 276.933 215.92 269.64 226.284C258.178 242.571 254.984 282.475 275.946 292.501C294.587 301.416 326.871 292.286 336.969 272.84C346.399 254.677 346.541 217.584 327.695 206.067C319.022 200.767 287.483 200.873 297.647 200.873C322.556 200.873 342.234 197.726 366.275 190.857C402.789 180.425 406.635 123.337 412.459 92.924C414.255 83.5463 419.055 32.636 395.21 54.7151C372.372 75.8612 377.774 110.887 377.774 139.109C377.774 172.14 367.021 202.259 359.597 234.074C354.243 257.022 360.252 272.462 384.452 276.92C411.124 281.834 424.759 254.205 432.862 234.26C443.258 208.668 456.472 190.201 485.353 190.857C495.591 191.09 498.83 194.979 502.788 204.027C504.418 207.752 504.643 216.083 504.643 216.083C504.643 216.083 489.335 193.115 477.934 194.382C466.427 195.66 463.432 204.741 457.16 212.744C450.668 221.027 440.389 226.042 434.531 235.002C426.29 247.606 443.016 267.834 454.934 272.469C475.209 280.353 491.209 271.164 499.449 252.437C505.415 238.878 510.033 222.706 513.175 208.293C516.14 194.689 514.659 183.439 514.659 204.212C514.659 224.272 499.725 266.915 530.239 270.614C565.422 274.878 624.351 254.311 634.85 217.567" stroke="url(#paint0_linear_4_50)" stroke-width="14" stroke-linecap="round"/>
                <defs>
                    <linearGradient id="paint0_linear_4_50" x1="31.5" y1="41.165" x2="611" y2="335.165" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#0FDFEC"/>
                        <stop offset="1" stop-color="#A11FDE"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}

export default Hola;