import React from 'react';
import styles from "../PoweredByTvSectionStyle/ThreedVerse.module.css"
import {Stack} from "@mui/material";

function ThreedVerse(props) {
    return (
        <div>
            {/*<img src={"/assets/3dverse.png"}/>*/}
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
            >
                <img className={styles.logo} src={"/assets/3DVERSELOGO.jpg"}/>
            </Stack>

        </div>
    );
}


export default ThreedVerse;