import React from "react";
import styles from "./TbotLogo.module.css"

function Tbot() {
    return (
        <div className='welcome_div'>
            <div className={styles.bouncingtext}>
                <div className={styles.b}>T</div>
                <div className={styles.o}>b</div>
                <div className={styles.u}>o</div>
                <div className={styles.n}>t</div>
                <div className={styles.shadow}></div>
                <div className={styles.shadowtwo}></div>
            </div>
        </div>
    )
}

export default Tbot;