import React from 'react';
import styles from "../PoweredByTvSectionStyle/WeareHiring.module.css"

function WeareHiring(props) {
    return (
        <div>
            <p className={styles.Hiring}>We are Hiring</p>
        </div>
    );
}

export default WeareHiring;