import React from 'react';
import styles from "../PoweredByTvSectionStyle/PoweredBy.module.css"
import {Stack} from "@mui/material";

function PoweredBy(props) {
    return (
        <div>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
            >
            <p className={styles.poweredby}>Powered By</p>
            </Stack>
        </div>
    );
}

export default PoweredBy;