import HelloAnimation from "./WELCOME_ANIMATION/HelloAnimation";
import Namaskar from "./WELCOME_ANIMATION/Namaskar";
import Hola from "./WELCOME_ANIMATION/Hola";
import Bonjour from "./WELCOME_ANIMATION/Bonjour";
import Ola from "./WELCOME_ANIMATION/Ola";
import Hai from "./WELCOME_ANIMATION/Hai";
import Salute from "./WELCOME_ANIMATION/Salute";
import React from "react";

function AnimatedWriter({ number}){
    if (number===1) {
        return <HelloAnimation/>
    }
    else if (number===2) {
        return <Namaskar/>
    }
    else if (number===3) {
        return  <Hola/>
    }
    else if (number===4) {
        return <Bonjour/>
    }
    else if (number===5) {
        return <HelloAnimation/>
    }
    else if (number===6) {
        return <Ola/>
    }
    else if (number===7) {
        return <Hai/>
    }
    else if (number===8) {
        return  <Salute/>
    }
}

export default AnimatedWriter;