import React from 'react';
import styles from "../PoweredByTvSectionStyle/Join.module.css"
import {Stack} from "@mui/material";
function Join(props) {
    return (
        <div>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
            >
                <div className={styles.buttoncenter}>
                    {/*<a href="https://forms.gle/75EGyWGebadXqq5L9" className={styles.joinbutton} target="_blank" rel="noopener noreferrer">Join Beta</a>*/}
                    <a href="https://forms.gle/75EGyWGebadXqq5L9"  target="_blank" rel="noopener noreferrer">Join</a>
                </div>
            </Stack>
        </div>
    );
}

export default Join;