import React from 'react';
import styles from "./Componentsstyles/Footer.module.css"
import {Stack} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin,
    faXTwitter,
    faWhatsapp
} from "@fortawesome/free-brands-svg-icons";

function Footer(props) {
    return(
<div className={styles.footer}>
    <p className={styles.follow_us_on}>FOLLOW US ON </p>
    <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        // spacing={10}
        spacing={6}
    >
        <a href="https://in.linkedin.com/company/3d-verse-experience"  target="_blank" rel="noopener noreferrer"
           className={styles.linkedin}>
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>

        <a href="https://www.youtube.com/@3DVerseExperience"  target="_blank" rel="noopener noreferrer"
           className={styles.youtube}>
            <FontAwesomeIcon icon={faYoutube} size="2x" />
        </a>

        <a href="https://twitter.com/3DVerseTech"  target="_blank" rel="noopener noreferrer"
           className={styles.X}>
            <FontAwesomeIcon icon={faXTwitter} size="2x" />
        </a>

        <a href="https://www.instagram.com/try3dverse/"  target="_blank" rel="noopener noreferrer"
           className={styles.instagram}>
            <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>


        <a href="https://wa.me/917208191693"  target="_blank" rel="noopener noreferrer"
           className={styles.whatsapp}>
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
        </a>


    </Stack>
</div>
);
}


export default Footer;