import React from 'react';
import styles from "../../../../../Componentsstyles/WELCOME_ANIMATION/Ola.module.css";

function Ola(props) {
    return (
        <div className={styles.container}>
            <svg className={styles.text} width="730" height="295" viewBox="0 0 730 295" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="730" height="295" fill="black"/>
                <path d="M220.389 109.865C222.462 106.179 232.408 99.8493 228.179 99.8493C214.833 99.8493 203.641 103.614 193.865 113.389C169.518 137.736 167.863 200.607 187.188 228.387C212.44 264.687 255.563 246.97 276.774 216.331C291.677 194.805 289.055 125.647 260.452 113.389C240.492 104.835 210.25 115.487 207.219 139.727C202.926 174.078 257.836 175.052 282.339 176.452C297.707 177.331 319.766 179.857 333.531 171.445C352.942 159.583 356.21 129.543 360.611 110.051C362.354 102.335 372.169 5.55831 355.418 29.9233C329.763 67.2403 341.294 123.256 336.499 165.88C334.227 186.076 328.749 197.777 337.241 217.444C342.613 229.883 355.239 240.072 369.144 240.072C411.444 240.072 398.562 182.576 425.529 164.767C439.209 155.734 456.685 154.786 470.787 162.542C482.484 168.975 475.958 171.155 466.706 169.219C434.4 162.457 403.199 182.986 411.433 220.04C416.79 244.149 456.08 249.343 468.19 229.314C478.755 211.842 487.057 192.138 487.48 171.445C487.735 158.948 480.034 191.651 478.948 195.928C475.29 210.331 471.455 237.147 490.447 239.33C525.047 243.307 560.603 237.28 594.316 230.056" stroke="url(#paint0_linear_4_56)" stroke-width="8" stroke-linecap="round"/>
                <defs>
                    <linearGradient id="paint0_linear_4_56" x1="384.158" y1="26" x2="384.158" y2="248.651" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#2FD9E3"/>
                        <stop offset="1" stop-color="#EC0FAE"/>
                    </linearGradient>
                </defs>
            </svg>

        </div>
    );
}

export default Ola;