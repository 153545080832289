import React from 'react';
import styles from "../../../../../Componentsstyles/WELCOME_ANIMATION/Hai.module.css";

function Hai(props) {
    return (
        <div className={styles.container}>
            <svg className={styles.text}  width="1001" height="388" viewBox="0 0 1001 388" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="1001" height="388" fill="black"/>
                <path d="M340 281.829C367.81 281.829 388.173 237.308 398.581 216.943C416.622 181.645 425.114 146.231 425.114 106.873C425.114 98.9699 426.981 86.8323 415.657 88.0906C405.762 89.1899 397.025 100.985 394.378 109.763C389.425 126.187 392.014 145.52 392.014 162.434C392.014 183.554 386.31 204.03 384.396 225.087C382.135 249.949 377.828 299.692 377.828 299.692C377.828 299.692 380.78 284.975 384.002 278.283C389.805 266.23 402.856 252.129 413.818 244.526C424.517 237.106 451.572 233.526 451.121 252.932C450.905 262.194 434.594 287.343 446.917 296.015C467.265 310.334 490.992 288.378 497.88 270.008C499.554 265.544 510.984 221.03 496.042 228.502C480.095 236.475 465.863 263.234 473.45 280.778C480.095 296.145 504.767 297.904 518.371 291.286C541.783 279.897 541.74 250.376 547.53 228.502C551.546 213.33 536.234 258.516 536.234 274.211C536.234 292.238 553.386 292.892 569.334 287.871C573.711 286.493 613.052 271.183 595.341 265.279" stroke="url(#paint0_linear_4_59)" stroke-width="8" stroke-linecap="round"/>
                <path d="M536.065 176.821C529.119 170.309 531.337 179.104 531.337 184.834C531.337 191.669 532.208 194.958 540.531 193.109C555.926 189.688 556.639 168.169 540.794 165" stroke="url(#paint1_linear_4_59)" stroke-width="5" stroke-linecap="round"/>
                <defs>
                    <linearGradient id="paint0_linear_4_59" x1="469.948" y1="88" x2="469.948" y2="300.625" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#33EBE0"/>
                        <stop offset="1" stop-color="#E712FA"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_4_59" x1="541.69" y1="165" x2="541.69" y2="193.618" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#33EBE0"/>
                        <stop offset="1" stop-color="#EC0FAE"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}

export default Hai;