import React from 'react';
import {Stack} from "@mui/material";
import styles from "./HeroStyling/Hero.module.css";

function Hero(props) {
    return (
        <div className={styles.hero}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <img className={styles.tdlogo} src={"/assets/3DVERSELOGO.jpg"}/>
                </Stack>
            <p className={styles.revolutionizing}>Revolutionizing The Way We Experience Everything</p>
            {/*<div className={styles.border}></div>*/}
        </div>
    );
}

export default Hero;