import logo from './logo.svg';
import './App.css';
import Hero from "./Components/HeroSection/Hero";
import TbotHero from "./Components/TbotSection/HeroSection/TbotHero";
import GKnow from "./Components/gKnowSection/GKnow";
import Vr from "./Components/VRSection/Vr";
import PoweredByTV from "./Components/PoweredBySection/PoweredByTVSection/PoweredByTV";


function App() {
  return (
   <div>
<Hero/>
     <TbotHero/>
     <GKnow/>
     <Vr/>
     <PoweredByTV/>
   </div>
  );
}

export default App;
